$primary-color: #53565A !default;
$secondary-color: #A7BDB1 !default;
$primary-background-color: #ebebe5 !default;
$secondary-background-color: #D9D9D6 !default;
$tertiary-color: #AD7C59 !default;
$tertiary-background-color: $secondary-color !default;


$link-color: $tertiary-color !default;

$main-font: 'Josefin Slab', serif !default;
$heading-font: $main-font !default;
$nav-font: $heading-font !default;
$italic-font: 'Yellowtail', cursive !default;
$button-font: $main-font !default;
$button-color: $primary-color !default;
$button-background-color: $primary-background-color !default;

$floorplan-featured-color: $primary-color !default;
$floorplan-hover-color: $primary-background-color !default;
$floorplan-hero-featured-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
