@import "src/assets/variables";

.section-hero {
  background-image: url("./Hero.png");
  //opacity: 0.8;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 25rem;

  .hero-left {
    width: 100vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;

    .hero-search {
      margin: 3rem;
      padding: 1rem 1rem 2rem;
      background: rgba(234, 233, 227, .9);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      @media(min-width: $large-size) {
        max-width: 20rem;
      }
      header {
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
          margin: .5rem;
          padding: .5rem;
          text-transform: none;
          font-weight: 400;
          font-size: 1.2rem;
          width: fit-content;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .muncher-button {
        width: 100%;
      }

      .hero-input {
        display: flex;
        justify-content: space-between;
      }

      .hero-input-element {
        h6 {
          margin: .3rem;
          text-align: left;
        }

        margin-top: 1rem;
        margin-bottom: 1rem;

        .muncher-button {
          justify-content: space-between;
          min-width: 7rem;
          @media(min-width: $medium-size) {
            min-width: 10rem;
          }
        }

        .muncher-dropdown--content {
          .content-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem;
            min-width: 6rem;
          }

          .muncher-button {
            display: block;
            font-size: .7rem;
            font-weight: bold;
            min-width: auto;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .hero-right {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .hero-input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-button {
    a {
      color: $primary-background-color;
    }

    a:hover {
      opacity: 1;
    }
  }

  @media(min-width: $large-size) {
    height: 35rem;
    justify-content: space-between;
    .hero-left {
      width: 50vw;

    }
    .hero-right {
      width: 50vw;
      text-align: right;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;

      .hero-right-content {
        width: 100%;

        header {
          font-family: $sans-serif-font;
          background: $tertiary-color;
          padding: 1.5rem 2rem;
          color: $primary-background-color;
          font-size: 1.8rem;
          font-weight: 400;
          display: flex;
          margin: 0;
        }

        blockquote {
          font-size: 3rem;
          font-weight: 400;
          color: $primary-background-color;
          text-align: left;
          text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
          letter-spacing: .1rem;
        }
      }

    }
  }

}